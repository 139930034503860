<template>
  <div>
    <el-table
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
    >
      <el-table-column label="提交人" prop="worker_name"></el-table-column>
      <el-table-column label="提交时间" prop="create_at"></el-table-column>
      <el-table-column
        label="终止合同商户"
        prop="contact_name"
      ></el-table-column>
      <el-table-column
        label="终止合同编码"
        prop="contract_no"
      ></el-table-column>
      <el-table-column label="原因" prop="over_reason"></el-table-column>
      <el-table-column label="凭证">
        <template #default="{ row }">
          <pop tips="查看" @myclick="checkvoucher(row, 'over_voucher')">
            <img src="../../../unit/img/yl.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <pop tips="审批" @myclick="handlesh(row)">
            <img src="../../../unit/img/sh.png" class="icon" alt="" />
          </pop>
          <pop
            tips="详情"
            @myclick="
              $openNewTab(`/contract/contractdetail?id=${row.contract_id}`)
            "
            popLeft
          >
            <img src="../../../unit/img/xq.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
    </el-table>
    <div class="public-page">
      <el-pagination
        :total="total"
        :current-page="params.page"
        :page-size="params.pageSize"
        layout="total,prev,pager,next"
        next-text="下一页"
        prev-text="上一页"
        @current-change="getList"
      ></el-pagination>
    </div>
    <!-- Pingz -->
    <el-dialog
      title="凭证"
      :visible.sync="vouchervisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-carousel indicator-position="outside" :autoplay="false">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <img class="voucher-img" :src="$common.hashost(item)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <el-dialog
      title="审批"
      :visible.sync="spvisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="备注">
          <el-input
            v-model="spform.over_remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "./mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        over_is_agree: 10,
      },
      total: 0,
    };
  },
  created() {
    // this.getList();
  },

  methods: {
    getList(page = 1) {
      this.params.pageNum = page;
      this.$request
        .HttpPost("/contract_agree/list", this.params)
        .then((res) => {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        });
    },
    spconfirm(type) {
      this.spform.over_is_agree = type;
      this.$request
        .HttpPost("/contract_agree/isAgreeEdit", this.spform)
        .then((res) => {
          this.$common.notifySuccess("操作成功");

          this.spvisible = false;
          this.getList();
          this.$emit("refresh", "zzjm");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.public-page {
  text-align: right;
  margin-top: 20px;
}
.voucher-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
