<template>
  <div>
    <el-table
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      v-loading="loading"
    >
      <el-table-column label="提交人" prop="worker_name"></el-table-column>
      <el-table-column label="提交时间" prop="create_at"></el-table-column>
      <el-table-column label="缴费商户" prop="contact_name"></el-table-column>
      <el-table-column label="缴费费用" prop="bill_name"></el-table-column>
      <el-table-column
        label="缴费金额(元)"
        prop="reduce_price"
      ></el-table-column>
      <el-table-column label="原因" prop="coupon_reason"></el-table-column>
      <el-table-column label="凭证">
        <template #default="{ row }">
          <pop tips="查看" @myclick="checkvoucher(row)">
            <img src="../../../unit/img/yl.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <pop tips="审批" @myclick="handlesh(row)">
            <img src="../../../unit/img/sh.png" class="icon" alt="" />
          </pop>
          <pop tips="详情" @myclick="goUrl(row)" popLeft>
            <!-- /charge/merchantbill/basebilldetail?merchants_id=${row.merchants_id} -->
            <img src="../../../unit/img/xq.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- Pingz -->
    <el-dialog
      title="凭证"
      :visible.sync="vouchervisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-carousel
        height="700px"
        indicator-position="outside"
        :autoplay="false"
      >
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <el-image
            :src="$common.hashost(item)"
            class="voucher-img"
            fit="contain"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <el-dialog
      title="审批"
      :visible.sync="spvisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="备注">
          <el-input
            v-model="spform.agree_remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "./mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      tableData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        is_agree: 10,
      },
    };
  },
  methods: {
    getList(page = 1) {
      this.tableData = [];
      this.total = 0;
      this.loading = true;
      this.$request
        .HttpGet("/bill_coupon/list", this.formInline)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange(page = 1) {
      this.formInline.pageNum = page;
      this.getList();
    },
    spconfirm(type) {
      this.spform.is_agree = type;
      this.$request
        .HttpPost("/bill_coupon/isAgree", {
          is_agree: type,
          bill_coupon_id: this.spform.bill_coupon_id,
          agree_remark: this.spform.agree_remark,
        })
        .then((res) => {
          this.spvisible = false;
          this.$common.notifySuccess("操作成功");
          this.getList();
          this.$emit("refresh");
        });
    },
    goUrl(row) {
      let url =
        row.bill_key == 20
          ? `/charge/merchantbill/daybilldetail?merchants_id=${row.merchants_id}&bill_key=20&merchants_stall_id=${row.merchants_stall_id}`
          : `/charge/merchantbill/basebilldetail?merchants_id=${row.merchants_id}&bill_key=10`;
      console.log(row);
      // this.$router.push(url);
      this.$openNewTab(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
