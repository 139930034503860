<template>
  <div class="feesjm">
    <el-table :header-cell-style="{
      'text-align': 'center',
      background: 'rgb(245, 245, 245)',
    }" :cell-style="{ 'text-align': 'center' }" :data="tableData" v-loading="loading">
      <el-table-column label="提交人" prop="worker_name"></el-table-column>
      <el-table-column label="提交时间" prop="create_at"></el-table-column>
      <el-table-column label="减免商户" prop="name"></el-table-column>
      <el-table-column label="减免金额（元）" prop="reduce_price"></el-table-column>
      <el-table-column label="减免原因" prop="coupon_reason"></el-table-column>
      <el-table-column label="凭证">
        <template #default="{ row }">
          <pop tips="查看" @myclick="checkvoucher(row)">
            <img src="../../../unit/img/yl.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <pop tips="审批" @myclick="handlesh(row)">
            <img src="../../../unit/img/sh.png" class="icon" alt="" />
          </pop>
          <pop tips="详情" @myclick="openWindow(row)" popLeft>
            <img src="../../../unit/img/xq.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
        @current-change="getList"></el-pagination>
    </div>
    <!-- Pingz -->
    <el-dialog title="凭证" :visible.sync="vouchervisible" append-to-body :close-on-click-modal="false">
      <el-carousel height="700px" indicator-position="outside" :autoplay="false">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <!-- <img class="voucher-img" :src="$common.hashost(item)" alt="" /> -->
          <el-image :src="$common.hashost(item)" class="voucher-img" fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <el-dialog title="审批" :visible.sync="spvisible" append-to-body :close-on-click-modal="false">
      <el-form>
        <el-form-item label="备注">
          <el-input v-model="spform.agree_remark" type="textarea" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "./mixins";
export default {
  mixins: [mixins],
  data () {
    return {
      tableData: [],
      total: 0,
    };
  },
  methods: {
    openWindow (row) {
      this.$openNewTab(
        row.apply_range == 10
          ? "/charge/jmsetting"
          : `/charge/merchantbill/basebilldetail?merchants_id=${row.merchants_id}`
      );
    },
    getList (page = 1) {
      this.loading = true;
      this.total = 0;
      this.tableData = [];
      this.$request
        .HttpGet("/property/coupon/list", {
          coupon_type: 10,
          pageNum: page,
          is_agree: 10,
        })
        .then((res) => {
          this.loading = false;
          if (res.data) {
            if (Array.isArray(res.data)) {
              return (this.tableData = res.data);
            }
            if (res.data.list) {
              this.tableData = res.data.list;
            }
            this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    spconfirm (type) {
      this.spform.is_agree = type;
      this.$request.HttpPost("/coupon/isAgree", this.spform).then((res) => {
        this.spvisible = false;
        this.spform.agree_remark = "";
        this.getList();
        this.$emit("refresh");
        this.$common.notifySuccess("操作成功");
        console.log(res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feesjm {
  width: 100%;
  // height: 500px;
}

.voucher-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
