<template>
  <div class="refund">
    <el-table :data="refundList" :header-cell-style="{
      'text-align': 'center',
      background: 'rgb(245, 245, 245)',
    }" :cell-style="{ 'text-align': 'center' }" v-loading="refundloading">
      <el-table-column label="退款提交时间" prop="create_at"></el-table-column>
      <el-table-column label="退款提交人" prop="worker_name"></el-table-column>
      <el-table-column label="退款金额" prop="refund_money"></el-table-column>
      <el-table-column label="退款账号" prop="refund_number"></el-table-column>
      <el-table-column label="账号户名" prop="refund_name"></el-table-column>
      <el-table-column label="备注" prop="refund_remarks"></el-table-column>
      <el-table-column label="凭证" prop="refund_image">
        <template slot-scope="scope">
          <div class="slot-box">
            <pop tips="查看凭证" @myclick="checkdgong(scope.row)">
              <img class="icon" src="../../../unit/img/yl.png" alt />
            </pop>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="slot-box">
            <pop tips="审批" :accessId="86190" @myclick="dealWith(scope.row)">
              <img class="icon" src="../../../unit/img/sh.png" alt />
            </pop>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
        @current-change="getList"></el-pagination>
    </div>
    <el-dialog title="退款审批" :visible.sync="dialogFormVisible" append-to-body :close-on-click-modal="false">
      <el-form>
        <el-form-item label="备注">
          <el-input v-model="refund_desc" autocomplete="off" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="noSubmint">拒绝</el-button>
        <el-button type="primary" @click="okSubmit">同意</el-button>
      </div>
    </el-dialog>

    <el-dialog title="凭证" :visible.sync="dgvisible" append-to-body :close-on-click-modal="false">
      <el-image class="dgimg" :src="item" alt="" v-for="item in gong_voucher" :key="item"
        :preview-src-list="gong_voucher">
      </el-image>
      <template #footer>
        <myButton @click="dgvisible = false">关闭</myButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import confirmVue from "../../../unit/components/confirm.vue";
import { getImg } from "../../../plugin/common";
export default {
  name: "refund",
  components: {
    confirmVue,
  },
  data () {
    return {
      refundList: [],
      total: 0,
      refundloading: false,
      dialogFormVisible: false,
      refund_desc: "",
      bill_refund_id: "",
      dgvisible: false,
      gong_voucher: []
    };
  },
  methods: {
    checkdgong (row) {
      if (!row.refund_image) {
        return this.$message({ type: "warning", message: "暂无凭证" });
      }
      this.gong_voucher = getImg(row.refund_image);
      if (!this.gong_voucher || this.gong_voucher.length === 0) {
        return this.$message({ type: "warning", message: "暂无凭证" });
      }
      this.dgvisible = true;
    },
    getList (page = 1) {
      this.refundloading = true;
      this.$request
        .HttpGet("/bill_refund/list", {
          pageSize: 999,
          status: 0,
          pageNum: page,
        })
        .then((res) => {
          if (res.data) {
            this.total = res.data.total;
            this.refundList = res.data.list;
          }
        })
        .finally(() => {
          this.refundloading = false;
        });
    },
    noSubmint () {
      this.$request
        .HttpPost("/bill_refund/edit", {
          status: 30,
          refund_desc: this.refund_desc,
          bill_refund_id: this.bill_refund_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
          this.dialogFormVisible = false;
        });
    },
    okSubmit () {
      this.$request
        .HttpPost("/bill_refund/edit", {
          status: 1,
          refund_desc: this.refund_desc,
          bill_refund_id: this.bill_refund_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
          this.dialogFormVisible = false;
        });
    },
    dealWith (row) {
      this.bill_refund_id = row.bill_refund_id;
      this.dialogFormVisible = true;
      // this.$myconfirm('退款审批', '提示',null,'同意','拒绝').then(() => {
      //   this.$request.HttpPost('/bill_refund/edit', {
      //     status: 1,
      //     bill_refund_id: row.bill_refund_id
      //   }).then(res => {
      //     this.$common.notifySuccess(res.msg);
      //     this.getList();
      //   })
      //
      // })
      // .catch(err=>{
      //     this.$request.HttpPost('/bill_refund/edit', {
      //     status: 30,
      //     bill_refund_id: row.bill_refund_id
      //   }).then(res => {
      //     this.$common.notifySuccess(res.msg);
      //     this.getList();
      //   })
      //   })
    },
  },
};
</script>
<style lang="scss" scoped>
.dgimg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
</style>
