<template>
  <div class="opercenter">
    <NavBar name="操作台"></NavBar>
    <div class="panel">
      <div class="panelList" @click="goUrl('/charge/addCharge', 79603)">
        <img src="@/assets/img/wuyeshoufei.png" alt="" />
        <span>综合收费</span>
      </div>
      <div class="panelList" @click="open('dialogFormVisible', 79604)">
        <img src="@/assets/img/yixiangjin.png" alt="" />
        <span>意向金收费</span>
      </div>
      <div class="panelList" @click="goUrl('/contract/addedcontract', 79605)">
        <img src="@/assets/img/chuangjianhetong.png" alt="" />
        <span>创建合同</span>
      </div>
      <div class="panelList"></div>
    </div>
    <div class="title">
      待办事件
      <span>({{ (statistics && statistics.Pending.totalPending) || 0 }})</span>
    </div>
    <div class="dspht">
      <div class="item" @click="goUrl('/contract/contractlist', 79606)">
        <img class="itemImg" src="../../assets/datapreview/sphts.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.auditorTotal) || 0 }}
          </div>
          <div>
            <span>待处理合同数</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="goUrl('/contract/contractlist?status=70', 79607)">
        <img class="itemImg" src="../../assets/datapreview/jjdqht.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.expireTotal) || 0 }}
          </div>
          <div>
            <span>即将到期合同数</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="goUrl('/complain/complainpreview', 79608)">
        <img class="itemImg" src="../../assets/datapreview/ts.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.complaintTotal) || 0 }}
          </div>
          <div>
            <span>投诉事件</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="handlejmtype('refund', 79609)">
        <img class="itemImg" src="../../assets/datapreview/tk.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.billRefundTotal) || 0 }}
          </div>
          <div>
            <span>退款申请</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="handlejmtype('feesjm', 79610)">
        <img class="itemImg" src="../../assets/img/feiyongjinmian.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.couponType10) || 0 }}
          </div>
          <div>
            <span>费用减免审批</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <!-- <div class="item" @click="handlejmtype('setfeesjm', 79611)">
        <img class="itemImg" src="../../assets/img/jiaofeishenpi.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.billCouponTotal) || 0 }}
          </div>
          <div>
            <span>设置缴费审批</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div> -->
      <div class="item" @click="handlejmtype('activesp', 79612)">
        <img class="itemImg" src="../../assets/img/youhuihuodong.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.couponType20) || 0 }}
          </div>
          <div>
            <span>优惠活动审批</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="$router.push('/contract/batchcontract', 79613)">
        <img class="itemImg" src="../../assets/img/plhtsp.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.contractAgreeTotal) || 0 }}
          </div>
          <div>
            <span>批量合同导入审批</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
      <div class="item" @click="handlejmtype('zzjm', 79614)">
        <img class="itemImg" src="../../assets/img/zzhtsp.png" alt />
        <div>
          <div class="count">
            {{ (statistics && statistics.Pending.contractOverTotal) || 0 }}
          </div>
          <div>
            <span>终止合同审批</span>
            <img class="icon" src="../../assets/datapreview/go.png" alt />
          </div>
        </div>
      </div>
    </div>

    <!-- yxj -->
    <el-dialog :close-on-click-modal="false" title="新增" :visible.sync="dialogFormVisible" width="880px" @close="close">
      <el-form :model="ruleform" :rules="rules" ref="ruleform" label-width="110px">
        <el-form-item label="意向人姓名" prop="user_name">
          <el-input v-model="ruleform.user_name" class="public-input" clearable placeholder="请输入意向人姓名"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="user_phone">
          <el-input type="number" v-model="ruleform.user_phone" class="public-input" clearable placeholder="请输入联系电话"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="user_no">
          <el-input v-model="ruleform.user_no" class="public-input" clearable placeholder="请输入身份证号"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="意向金" prop="purpose_money">
          <el-input v-model="ruleform.purpose_money" class="public-input" clearable placeholder="请输入意向金"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="预定时间" prop="purpose_start_time" ref="purpose_start_time">
          <el-date-picker v-model="presetTime" type="daterange" @change="changeDate" :picker-options="pickerOptions"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="意向商铺" prop="merchants_stall_ids">
          <el-select v-model="ruleform.merchants_stall_ids" clearable class="public-input" placeholder="请选择意向商铺">
            <el-option :label="item.merchants_stall_number" :value="item.merchants_stall_id"
              v-for="item in merchantsStall" :key="item.merchants_stall_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleform.purpose_remarks" class="public-input" clearable placeholder="请输入备注"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="收款方式" prop="pay_type">
          <el-radio-group v-model="ruleform.pay_type">
            <el-radio :label="30">现金收款</el-radio>
            <el-radio :label="32">扫码收款</el-radio>
            <el-radio :label="33">刷卡支付</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="dialogFormVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="confirm">确 定</MyButton>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="jmtitle" :visible.sync="rateReliefVisible" @close="getStatistics"
      width="880px">
      <component :is="jmtype" ref="jmData" @refresh="getStatistics"></component>

      <div slot="footer" class="dialog-footer">
        <MyButton type="primary" @click="rateReliefVisible = false">关闭</MyButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import feesjm from "./tables/feesjm.vue";
import setfeesjm from "./tables/setfeesjm.vue";
import activesp from "./tables/activesp.vue";
import batchjm from "./tables/batchjm.vue";
import zzjm from "./tables/zzjm.vue";
import refund from "./tables/refundtable.vue";
export default {
  components: {
    feesjm,
    setfeesjm,
    activesp,
    batchjm,
    zzjm,
    refund,
  },
  data () {
    return {
      statistics: null,
      dialogFormVisible: false,
      ruleform: {
        user_name: "",
        user_phone: "",
        user_no: "",
        purpose_money: "",
        purpose_start_time: "",
        purpose_end_time: "",
        merchants_stall_ids: "",
        purpose_remarks: "",
        pay_type: "",
      },
      rules: {
        user_name: [
          {
            required: true,
            message: "请输入意向人姓名",
            trigger: "blur",
          },
        ],
        user_phone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        user_no: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "blur",
          },
        ],
        purpose_money: [
          {
            required: true,
            message: "请输入意向金",
            trigger: "blur",
          },
        ],
        purpose_start_time: [
          {
            required: true,
            message: "请选择预定时间",
            trigger: "change",
          },
        ],
        merchants_stall_ids: [
          {
            required: true,
            message: "请选择意向商铺",
            trigger: "change",
          },
        ],
        purpose_remarks: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
        pay_type: [
          {
            required: true,
            message: "请选择收款方式",
            trigger: "change",
          },
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      presetTime: "",
      merchantsStall: [],
      rateReliefVisible: false,
      vouchervisible: false,
      voucher: [],
      setchargeVisible: false, //设置缴费审批
      setactiveVisible: false, //优惠活动审批
      jmpages: 1,
      jmtotal: 0,
      jmtype: "",
      jmtitle: "",
      refundVisible: false,
    };
  },
  created () {
    this.getStatistics();
  },
  methods: {
    getStatistics (component) {
      this.$request.HttpGet("/index/listTreat").then((res) => {
        this.statistics = res.data;
        // this.draw(res.data.MerchantsStall);
      });
      if (component == "zzjm") {
        this.rateReliefVisible = false;
      }
    },
    goUrl (url, id) {
      if (this.$common.hasRole(id)) {
        this.$router.push(url);
      } else {
        this.$message({ message: "暂无权限!", type: "error" });
      }
    },
    open (type, id) {
      if (this.$common.hasRole(id)) {
        this[type] = true;
      } else {
        this.$message({ message: "暂无权限!", type: "error" });
      }
    },
    close () {
      this.formInline = {
        pageNum: 1,
        pageSize: 10,
        purpose_start_time: "",
        purpose_end_time: "",
        purpose_no: "",
        merchants_stall_name: "",
        user_name: "",
        user_phone: "",
        user_no: "",
        purpose_status: "",
      };
    },
    confirm () {
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          if (this.ruleform.pay_type == 32) {
            this.scanPayshow();
            return false;
          }
          this.$request.HttpPost("/purpose/add", this.ruleform).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      });
    },
    // 扫码支付
    scanPayshow () {
      this.$refs.ruleform.validate((valid) => {
        this.qrpay = true;
        this.$nextTick(() => {
          this.$refs.qrcode.focus();
        });
      });
    },
    scanPay () {
      // this.$refs
      let params = {
        merchants_id: this.addform.merchants_id,
        discount: this.activityDiscount ? this.activityDiscount / 10 : 0,
        coupon_price: this.addform.derate_total_amount
          ? this.addform.derate_total_amount
          : 0, //市场减免金额
        balance_deduction: this.balanceMoney, //余额抵扣金额
        discount_money: this.discountHMoney(), //打折抵扣金额
        key_pay_price: this.addform.total_cost, //最终缴费金额
        qrcode: this.qrcode,
        bill_ids: this.bill_ids,
      };
      if (this.qrcode) {
        this.isScan = true;
        this.$request
          .HttpPost("/bill_offline/barcodeGun", params)
          .then((res) => {
            if (res.data) {
              this.getPayResult(res.data.payNo);
            }
          });
      }
      // this.getPayResult()
    },
    // 支付结果
    getPayResult (payNo) {
      let getTimer = setInterval(() => {
        this.$request
          .HttpPost("/bill_offline/barcodeGunSucceed", {
            payNo,
          })
          .then((res) => {
            if (res.data) {
              if (res.data.code == 0) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.$message({
                  message: "支付成功",
                  type: "success",
                });
                this.qrcode = "";
                this.$request
                  .HttpPost("/purpose/purposeReturn", {
                    orderNo: payNo,
                    status: 1,
                    merchants_stall_ids: this.ruleform.merchants_stall_ids,
                  })
                  .then(() => {
                    this.dialogFormVisible = false;
                  });
              } else if (res.data.code == -2) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.$message({
                  message: "支付失败",
                  type: "error",
                });
              }
            } else {
              clearInterval(getTimer);
              this.closeqrmask();
              this.$message({
                message: "支付失败",
                type: "error",
              });
            }
          });
      }, 5000);
    },

    changeDate (date) {
      if (date) {
        this.ruleform.purpose_start_time = date[0];
        this.ruleform.purpose_end_time = date[1];
        this.$refs.ruleform.validateField("purpose_start_time");
        this.getShop();
      } else {
        this.ruleform.purpose_start_time = "";
        this.ruleform.purpose_end_time = "";
        this.$refs.ruleform.clearValidate("purpose_start_time");
        this.merchantsStall = [];
      }
    },
    getShop () {
      this.$request
        .HttpGet("/merchants_stall/listAll", {
          endTime: this.ruleform.purpose_start_time,
        })
        .then((res) => {
          this.merchantsStall = res.data || [];
        });
    },
    checkvoucher (row) {
      if (!row.coupon_voucher)
        return this.$message({ message: "暂无凭证", type: "warning" });
      try {
        this.voucher = JSON.parse(row.coupon_voucher);
      } catch {
        this.voucher = [row.coupon_voucher];
      }
      this.vouchervisible = true;
    },
    spconfirm (type) {
      this.spform.is_agree = type;
      this.$request.HttpPost("/coupon/isAgree", this.spform).then((res) => {
        this.spvisible = false;
        console.log(res);
      });
    },
    handlejmtype (type, id) {
      if (this.$common.hasRole(id)) {
        this.jmtype = type;
        this.jmurl = "";
        if (type == "feesjm") {
          this.jmtitle = "费用减免审批";
        }
        if (type == "setfeesjm") {
          this.jmtitle = "设置缴费审批";
        }
        if (type == "activesp") {
          this.jmtitle = "优惠活动审批";

          // this.jmurl = ''
        }
        if (type == "batchjm") {
          this.jmtitle = "批量合同导入审批";
        }
        if (type == "zzjm") {
          this.jmtitle = "终止合同审批";
        }
        if (type == "refund") {
          this.jmtitle = "退款审批";
        }
        this.rateReliefVisible = true;
        this.$nextTick(() => {
          this.$refs.jmData.getList();
        });
        console.log("fsafdsaf");
      } else {
        this.$message({ message: "暂无权限!", type: "error" });
      }
    },
    jmgetlist (page = 1) {
      this.jmData = [];
      if (!this.jmurl) return;
    },
  },
};
</script>

<style lang="scss">
.opercenter {
  .panel {
    display: flex;
    margin-top: 20px;

    .panelList {
      width: calc((100% - 60px) / 4);
      height: 120px;
      border: 1px solid #dedede;
      margin-right: 20px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      cursor: pointer;

      img {
        width: 54px;
        height: 54px;
        margin-right: 15px;
      }

      span {
        font-size: 14px;
        color: #333333;
      }

      &:last-child {
        border: none;
        margin-right: 0;
      }
    }
  }

  .dspht {
    width: 100%;
    height: 220px;
    background: #ffffff;
    border: 1px solid #dedede;
    padding: 0 19px;
    box-sizing: border-box;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }

  .title {
    width: 100%;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding-left: 40px;
    margin: 30px 0 10px 0;
    box-sizing: border-box;

    span {
      font-weight: bolder;
    }
  }

  .item {
    flex-shrink: 0;
    width: 200px;
    height: 80px;
    // background: red;
    display: inline-flex;
    align-items: flex-end;
    cursor: pointer;

    .count {
      margin-bottom: 9px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    div {
      div {
        &:nth-child(2) {
          white-space: nowrap;
        }
      }

      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .itemImg {
    width: 40px;
    height: 40px;
    margin-right: 21px;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-left: 12px;
    cursor: pointer;
  }
}
</style>
