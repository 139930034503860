export default{
    data(){
        return{
            voucher: [],
            spform: {},
            spvisible: false,
            vouchervisible: false,
            loading:false,
            total:0
        }
    },
    methods:{
        handlesh(item) {
            if(item.coupon_id){
                this.spform.coupon_id = item.coupon_id;
            }
            if(item.bill_coupon_id){
              this.spform.bill_coupon_id = item.bill_coupon_id
            }
            if (item.agree_contract_id) {
              this.spform.agree_contract_id = item.agree_contract_id;
            }
            this.spvisible = true;
          },
        checkvoucher(row,type="coupon_voucher") {
          this.voucher = []
            if (!row[type])
              return this.$message({ message: "暂无凭证", type: "warning" });
            try {
              this.voucher = JSON.parse(row[type]);
            } catch {
              this.voucher = [row[type]];
            }
            if(this.voucher.length == 0)  return this.$message({ message: "暂无凭证", type: "warning" });
            this.vouchervisible = true;
          }
    }
}
