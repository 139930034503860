<template>
  <div>
    <el-table
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      v-loading="loading"
    >
      <el-table-column label="提交人" prop="worker_name"></el-table-column>
      <el-table-column label="提交时间" prop="create_at"></el-table-column>
      <el-table-column
        label="优惠折扣"
        prop="activity_discount"
      ></el-table-column>
      <el-table-column
        label="活动开始时间"
        prop="coupon_start_time"
      ></el-table-column>
      <el-table-column
        label="活动结束时间"
        prop="coupon_end_time"
      ></el-table-column>
      <el-table-column label="参与活动费用">
        <template #default="{ row }">
          {{ getCouponReason(row) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <pop tips="审批" @myclick="handlesh(row)">
            <img src="../../../unit/img/sh.png" class="icon" alt="" />
          </pop>
          <pop
            tips="详情"
            @myclick="$openNewTab(`/charge/specialOffer`)"
            popLeft
          >
            <img src="../../../unit/img/xq.png" class="icon" alt="" />
          </pop>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="getList"
      ></el-pagination>
    </div>
    <el-dialog
      title="审批"
      :visible.sync="spvisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="备注">
          <el-input
            v-model="spform.agree_remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "./mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      tableData: [],
      total: 0,
    };
  },
  methods: {
    getList(page = 1) {
      this.tableData = [];
      this.total = 0;
      this.loading = false;
      this.$request
        .HttpGet("/coupon/list", {
          coupon_type: 20,
          is_agree: 10,
          pageNum: page,
        })
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    spconfirm(type) {
      this.spform.is_agree = type;
      this.$request
        .HttpPost("/coupon/isAgree", {
          is_agree: type,
          expire_type: 20,
          coupon_type: 20,
          name: "优惠活动",
          coupon_id: this.spform.coupon_id,
          remark: this.spform.agree_remark,
        })
        .then((res) => {
          this.spvisible = false;
          this.$common.notifySuccess("操作成功");
          this.getList();
          this.$emit("refresh");
        });
      // this.$request.HttpPost("/coupon/isAgree", this.spform).then((res) => {
      //   this.spvisible = false;
      //   console.log(res);
      // });
    },
    getCouponReason(row) {
      if (row.coupon_reason) {
        let arr = row.coupon_reason.split(",");
        let text = [];
        for (let i in arr) {
          if (arr[i] == "10") {
            text.push("日常费用");
          }
          if (arr[i] == "39") {
            text.push("其他费用");
          }
          if (arr[i] == "40") {
            text.push("基础费用");
          }
        }
        return text.join(",");
      }
      return "";
    },
  },
};
</script>

<style></style>
