<template>
  <div>
     <el-table
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
      >
        <el-table-column label="提交人"></el-table-column>
        <el-table-column label="提交时间"></el-table-column>
        <el-table-column label="减免商户"></el-table-column>
        <el-table-column label="减免金额（元）"></el-table-column>
        <el-table-column label="减免原因"></el-table-column>
        <el-table-column label="凭证">
          <template #default="{ row }">
            <pop tips="查看" @myclick="checkvoucher(row)">
              <img src="../../../unit/img/yl.png" class="icon" alt="" />
            </pop>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <pop tips="审批" @myclick="handlesh(row)">
              <img src="../../../unit/img/sh.png" class="icon" alt="" />
            </pop>
            <pop
              tips="详情"
              @myclick="
                $openNewTab(`/charge/merchantbill/basebilldetail?id=${row.coupon_id}`)
              "
              popLeft
            >
              <img src="../../../unit/img/xq.png" class="icon" alt="" />
            </pop>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
export default {
props: {
    tableData:{
        type:Array,
        default:() => []
    },
  },
  data(){
    return {}
  },
  methods:{
    getList(){
      
    }
  }
}
</script>

<style>

</style>